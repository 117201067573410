import { useEffect, useState } from 'react';
import delay from 'delay';

/**
 * @param {number} duration
 * @param {onEndFunction} [onEnd]
 */
const useCountDown = (duration, onEnd) => {
    const [time, setTime] = useState(duration);

    useEffect(() => {
        setTime(duration);

        if (!duration) {
            return;
        }

        const runController = new AbortController();
        const endController = new AbortController();

        const ms = 1000;
        const delayOptions = { signal: runController.signal };

        /**
         * @param {number} time
         */
        const getTime = (time) => {
            const next = Math.max(0, time / ms - 1);
            if (next < 1) {
                runController.abort();
                if (typeof onEnd === 'function') {
                    onEnd(endController);
                }
            }
            return next * ms;
        };

        async function run() {
            try {
                while (true) {
                    setTime(getTime);
                    await delay(ms, delayOptions);
                }
            } catch (error) {}
        }

        run();

        return () => {
            runController.abort();
            endController.abort();
        };
    }, [duration, onEnd]);

    return time;
};

export default useCountDown;

/**
 * @callback onEndFunction
 * @param {AbortController} controller
 * @returns {unknown}
 */
