import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { bingoLobbyCtx } from '../../store/bingolobbycontext';
import { apiClient } from '../../utils/axios-client';

const Modalmobile = '(min-width: 1px) and (max-width: 935px)';
const mobile = '(min-width: 1px) and (max-width: 576px)';

const ModalDiv = styled.div`
    display: ${(props) => (props.display === 'true' ? 'initial' : 'none')};
    top: 5%;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 835px;
    height: 725px;
    background: white;
    border: 1px solid black;
    height: auto;
    overflow-y: scroll;
    max-height: 100%;

    @media ${Modalmobile} {
        width: 98%;
        height: auto;
    }
`;

const ModalHeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #a6a6a6;
    align-items: center;
    padding: 20px 30px 20px 30px;
    caret-color: transparent;
`;

const ModalTitle = styled.h1`
    font-size: 2rem;
    color: #636363;
    font-family: Roboto;
    font-weight: 600;
    margin: 0px;

    @media ${mobile} {
        font-size: 4.2vw;
    }
`;

const CloseButton = styled.button`
    outline: none;
    border: none;
    background: none;

    &:hover {
        cursor: pointer;
    }
`;

const ModalClose = styled.img`
    width: 23px;
    height: 23px;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
`;

const ModalBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px 40px 0px;
    caret-color: transparent;
`;

const UserIcon = styled.img`
    width: 157px;
    height: 157px;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
    caret-color: transparent;

    @media ${mobile} {
        width: 20.93vw;
        height: 20.93vw;
    }
`;

const AliasInput = styled.input`
    width: 668px;
    height: 58px;
    border-radius: 10px;
    border: solid 2px #ababab;
    background-color: #f5f5f5;
    margin: 0px 0px 0px 0px;
    font-size: 1.5rem;
    caret-color: black;

    background-image: url('/assets/icons/placeholdericon.png');
    background-size: 26px 30px;
    background-position: 15px center;
    background-repeat: no-repeat;
    text-indent: 50px;
    image-rendering: -webkit-optimize-contrast;

    @media ${Modalmobile} {
        width: 90vw;
        height: 40px;
        font-size: 3.2vw;
        margin: 0px 0px 0px 0px;
    }
`;

const InfoContainer = styled.div`
    width: 650px;
    height: 88px;
    border-radius: 10px;
    background-color: none;
    padding: 0px 0px 0px 0px;
    margin-top: 30px;

    display: flex;
    justify-content: center;

    @media ${Modalmobile} {
        width: 89vw;
        height: auto;
        margin-top: 4vw;
    }
`;

const InfoText = styled.p`
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: black;

    @media ${mobile} {
        font-size: 3vw;
    }
`;

const RedContainer = styled(InfoContainer)`
    width: 600px;
    background-color: #f8d7d7;
    height: auto;
    margin-top: 10px;
    padding: 0px 35px 0px 35px;

    @media ${Modalmobile} {
        width: 74vw;
    }
`;

const RedText = styled(InfoText)`
    color: #f02a2a;
`;

const ConfirmButton = styled.button`
    outline: none;
    border: none;
    width: 351px;
    height: 64px;
    margin-top: 80px;
    color: white;
    font-size: 2.25rem;
    font-weight: 500;

    border-radius: 32px;
    background: #00cc66;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background: #ababab;
    }

    @media ${mobile} {
        width: 46.8vw;
        height: 8.53vw;
        font-size: 4.864vw;
        margin-top: 10vw;
    }
`;

const CheckImage = styled.img`
    margin-top: 20px;
    width: 56px;
    height: 56px;
    image-rendering: -webkit-optimize-contrast;
`;

const GreenText = styled.h1`
    margin: 5px 0px 0px 0px;
    font-family: Roboto;
    font-size: 3rem;
    text-align: center;
    color: #0c6;
`;

const ProceedButton = styled(ConfirmButton)`
    margin-top: 20px;
    font-size: 1.5rem;

    @media ${mobile} {
        font-size: 3.2vw;
    }
`;

const BingoLobbyModal = (props) => {
    const { jsessionId, toggleChangeAliasModal } = props;

    const [newAlias, setNewAlias] = useState('');
    const [errorAlias, setErrorAlias] = useState({
        error: false,
        message: '',
    });
    const [aliasChanged, setAliasChanged] = useState(false);

    const { lobbyModalVisible, setLobbyModalVisible, launchData } =
        bingoLobbyCtx();

    const onCloseModal = () => {
        setNewAlias('');
        document.getElementById('aliasInput').value = null;
        setAliasChanged(false);
        setErrorAlias({
            error: false,
            message: '',
        });
        setLobbyModalVisible(false);
    };

    const onInputChange = (e) => {
        e.preventDefault();

        const inputData = e.target.value;
        setNewAlias(inputData);

        if (inputData.length > 15) {
            setErrorAlias({
                error: true,
                message: 'Alias must not exceed 15 characters.',
            });
            return;
        }

        setErrorAlias({
            error: false,
            message: '',
        });
    };

    const onConfirmModal = async () => {
        const { data } = await apiClient.put('/site/v2/player/alias', {
            alias: newAlias,
        });

        if (data.error) {
            setErrorAlias({
                ...errorAlias,
                error: data.error,
                message: data.message,
            });

            return;
        }
        setAliasChanged(true);
        toggleChangeAliasModal();
    };

    const onLaunchBingo = () => {
        const { launchUrl } = launchData;

        setNewAlias('');
        document.getElementById('aliasInput').value = null;
        setAliasChanged(false);
        setErrorAlias({
            error: false,
            message: '',
        });
        setLobbyModalVisible(false);

        const y = window.outerHeight / 2 + window.screenY - 768 / 2 - 50;
        const x = window.outerWidth / 2 + window.screenX - 1024 / 2;

        window.open(
            launchUrl,
            '_blank',
            `location=yes,top=${y},left=${x},height=768,width=1024,scrollbars=yes,status=yes`
        );
    };

    const ModalStates = () => {
        if (aliasChanged) {
            return (
                <>
                    <CheckImage src="/assets/icons/check.png" />
                    <GreenText>Saved!</GreenText>
                    <ProceedButton onClick={onLaunchBingo}>
                        PROCEED TO BINGO
                    </ProceedButton>
                </>
            );
        }
        if (errorAlias.error) {
            return (
                <>
                    <RedContainer>
                        <RedText>{errorAlias.message}</RedText>
                    </RedContainer>
                    <ConfirmButton onClick={onConfirmModal} disabled>
                        CONFIRM
                    </ConfirmButton>
                </>
            );
        }
        return (
            <>
                <ConfirmButton onClick={onConfirmModal}>CONFIRM</ConfirmButton>
            </>
        );
    };

    return (
        <ModalDiv display={lobbyModalVisible ? 'true' : 'false'}>
            <ModalHeaderDiv>
                <ModalTitle>Please create a player alias</ModalTitle>
                <CloseButton onClick={onCloseModal}>
                    <ModalClose
                        alt="closeModal"
                        src="/assets/icons/modalclose.png"
                    />
                </CloseButton>
            </ModalHeaderDiv>
            <ModalBody>
                <UserIcon alt="userIcon" src="/assets/icons/usericon.png" />
                <InfoContainer>
                    <InfoText>
                        It looks like you do not have an alias yet for Bingo.
                        Your alias will be displayed in chat when you are
                        chatting with others as well as on recent winners lists.
                    </InfoText>
                </InfoContainer>
                <AliasInput
                    id="aliasInput"
                    placeholder="Alias/Username"
                    onChange={onInputChange}
                    disabled={aliasChanged}
                />
                {ModalStates()}
            </ModalBody>
        </ModalDiv>
    );
};

export default BingoLobbyModal;
