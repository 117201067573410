import { useMemo } from 'react';

/**
 * @template {{ nextGameStarting: number }} Room
 * @param {Room[]} rooms
 */
export const useRooms = (rooms = []) =>
    useMemo(
        () =>
            rooms
                .slice()
                .sort((a, b) => new Date(a.startTime) - new Date(b.startTime)),
        [rooms]
    );
