import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderDiv = styled.div`
    margin-top: 20px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spin} 1.3s linear infinite;
`;

const Loader = () => (
  <LoaderDiv />
);

export default Loader;
