import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useCountDown from '../../hooks/usecountdown';
import { bingoLobbyCtx } from '../../store/bingolobbycontext';
import { apiClient } from '../../utils/axios-client';
import { millisecondsToHuman } from '../../utils/humanize';
import { openGameWindow } from '../../utils/open-game-window';

const mobile = '(min-width: 1px) and (max-width: 576px)';

const BingoContainer = styled.div`
    position: relative;
    width: 231px;
    height: 340px;
    background: #03002e;
    border-radius: 20px;
    border: 1px solid #000;
    margin: 0 1.3em 0 1.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    outline: none;
    padding: 0;
    image-rendering: -webkit-optimize-contrast;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
    caret-color: transparent;

    @media ${mobile} {
        width: 41vw;
        height: 70.1vw;
        margin: 0 1.8vw 0 1.8vw;
    }
`;

const BingoJackpot = styled.div`
    width: 90%;
    margin-top: 9.3rem;
    font-size: 1rem;
    color: #fff233;
    font-weight: 500;
    display: flex;
    visibility: ${(props) => (props.jackpot > 0 ? 'visible' : 'hidden')};
    flex-direction: row;
    justify-content: center;
    background: #094f8b;
    padding: 6px 5px 6px 5px;
    border-radius: 20px;

    @media ${mobile} {
        width: 36.3vw;
        margin-top: 21.5vw;
        margin-bottom: 0;
        font-size: 3.1vw;
    }
`;

const BingoInfoDiv = styled.div`
    margin: ${(props) =>
        props.jackpot > 0 ? '0.8rem 0 0 0' : '-0.8rem 0px 0.8rem 0px'};
    width: 13.5rem;
    height: 5rem;
    padding: 0vh 4.5px 0vh 4.5px;

    @media ${mobile} {
        width: 39vw;
        height: 50vw;
        padding: 0vh 1vw 0vh 1vw;
    }
`;

const BingoInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0vh 0vh 0vh 0vh;
    line-height: normal;
    white-space: nowrap;

    @media ${mobile} {
        font-size: 3vw;
    }
`;

const PlayNowButton = styled.button`
    width: 215px;
    height: 39px;
    background: #203dff;
    border: none;
    outline: none;
    color: white;
    border-radius: 23px;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    transition: all 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    @media ${mobile} {
        width: 36.3vw;
        height: 8vw;
        border-radius: 4vw;
        font-size: 3.8vw;
        margin-top: 0.78vw;
    }
`;

const PrePurchaseButton = styled(PlayNowButton)`
    background: #f96f19;
    margin-top: 8px;
`;

const PlayButtonImg = styled.img`
    height: 1.5rem;
    width: 1.7rem;
    margin-right: 10px;

    @media ${mobile} {
        height: 4.7vw;
        width: 4.1vw;
        margin-right: 2vw;
    }
`;

const ButtonsDiv = styled.div`
    color: white;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${mobile} {
        font-size: 16px;
    }
`;

const BingoImage = styled.img`
    position: absolute;
    top: 0;
    width: 231px;
    height: 144px;
    background-size: cover;
    image-rendering: -webkit-optimize-contrast;

    @media ${mobile} {
        width: 41vw;
        height: 20.53vw;
    }
`;

const RoomName = styled.h1`
    position: absolute;
    height: 42px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    top: 90px;
    color: white;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 15px;

    @media ${mobile} {
        height: 5.6vw;
        font-size: 2vw;
        top: 13vw;
        padding: 0px 5.33vw 0px 5.33vw;
        line-height: 2vw;
    }
`;

export const bingoImageMap = {
    12: '/assets/bingo/freeroom.png',
    32: '/assets/bingo/freeroom.png',
    15: '/assets/bingo/tournament.png',
    36: '/assets/bingo/tournament.png',
    13: '/assets/bingo/main.png',
    33: '/assets/bingo/main.png',
    14: '/assets/bingo/special.png',
    35: '/assets/bingo/special.png',
};

const BingoThumbnail = (props) => {
    const { bingoData, sessionToken, setModalOpen, aliasChange, lang } = props;

    // destructure to simplify component data references
    const {
        id: roomId,
        name: roomName,
        startTime,
        nextGame: {
            name: gameName,
            jackpot: { prize: jackpotPrize } = {},
            price: {
                amount: { card: cardCost },
            },

            jackpotPrizeFormat,
            gamePrizeFormat,
            cardCostFormat,
        },
    } = bingoData;

    const nextGameStart = useMemo(
        () => Math.max(0, new Date(startTime) - new Date()),
        [startTime]
    );

    const { setLobbyModalVisible, setLaunchDataItems, showNotification } =
        bingoLobbyCtx();

    const [imgSrc, setImgSrc] = useState('/assets/bingo/default.png');

    const mapImage = bingoData?.id && bingoImageMap[bingoData.id];

    useEffect(() => {
        if (mapImage) {
            setImgSrc(mapImage);
        }
    }, [mapImage]);

    const time = useCountDown(nextGameStart);

    const openPrepurchase = () => {
        setModalOpen(['/pre-purchases']);
    };

    const onBingoLaunch = async () => {
        const gameWindow = openGameWindow();

        try {
            const {
                data: { url },
            } = await apiClient.get(`/site/v2/bingo/rooms/${roomId}/launch`, {
                params: {
                    lang,
                },
            });

            if (aliasChange) {
                setLaunchDataItems({
                    launchURL: url,
                });
                setLobbyModalVisible(true);
                return;
            }

            gameWindow.location = url;
        } catch (e) {
            console.error(`launch error: ${JSON.stringify(e.response?.data)}`);

            showNotification({
                type: 'error',
                message: 'Error opening Bingo Room',
                messagetwo:
                    e.response?.data.error === 'Error: error.unexpected'
                        ? 'Please try logging in again.'
                        : null,
            });

            gameWindow.close();
        }
    };

    const imageError = () => {
        setImgSrc('/assets/bingo/default.png');
    };

    const gameStarted = nextGameStart === 0;

    const bingoSwitch = () => {
        if (!sessionToken) {
            return 'Log in to play';
        }

        return (
            <>
                <PlayNowButton onClick={onBingoLaunch}>
                    <PlayButtonImg
                        src="/assets/icons/playicon.png"
                        alt="playicon"
                    />
                    Play now!
                </PlayNowButton>
                <PrePurchaseButton onClick={openPrepurchase}>
                    <PlayButtonImg
                        src="/assets/icons/calendar.png"
                        alt="playicon"
                    />
                    Pre-Buy
                </PrePurchaseButton>
            </>
        );
    };

    return (
        <BingoContainer key={roomId}>
            <BingoImage src={imgSrc} onError={imageError} />
            <RoomName>{roomName}</RoomName>
            <BingoJackpot jackpot={jackpotPrize}>
                JACKPOT: &nbsp;
                {jackpotPrizeFormat}
            </BingoJackpot>
            <BingoInfoDiv jackpot={jackpotPrize}>
                <BingoInfo>
                    <div>Tickets:</div>
                    {parseFloat(cardCost) > 0 ? cardCostFormat : 'Free'}
                </BingoInfo>
                <BingoInfo>
                    <div>Prize:</div>
                    {gamePrizeFormat}
                </BingoInfo>
                <BingoInfo>
                    <div>Game:</div>
                    {gameName}
                </BingoInfo>
                {gameStarted ? null : (
                    <BingoInfo>
                        <div>Starts in:</div>
                        {millisecondsToHuman(time)}
                    </BingoInfo>
                )}
            </BingoInfoDiv>
            <ButtonsDiv>{bingoSwitch()}</ButtonsDiv>
        </BingoContainer>
    );
};

export default React.memo(BingoThumbnail);
