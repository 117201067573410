import axios from 'axios';

/**
 * @param {import('axios').AxiosRequestConfig} config
 */
export const sessionInterceptor = (config) => {
    const params = new URLSearchParams(location.search);
    config.headers['x-session-token'] = params.get('jsessionId');
    return config;
};

export const apiClient = axios.create({
    baseURL: '/api',
});

export const apiFetcher = (url) => apiClient.get(url).then((res) => res.data);

apiClient.interceptors.request.use(sessionInterceptor);
