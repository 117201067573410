import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { apiClient } from '../../utils/axios-client';
import { openGameWindow } from '../../utils/open-game-window';

const mobile = '(min-width: 1px) and (max-width: 576px)';

const ThumbnailDiv = styled.div`
    position: relative;
    width: 350px;
    height: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    caret-color: transparent;
    margin: 20px 40px 20px 40px;

    @media ${mobile} {
        height: 150px;
    }
`;

const SkillBingoImage = styled.img`
    width: 185px;
    height: 179px;
    image-rendering: -webkit-optimize-contrast;

    @media ${mobile} {
        transform: scale(0.8);
    }
`;

const ButtonsDiv = styled.div`
    position: absolute;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    right: 0px;

    @media ${mobile} {
        transform: scale(0.8);
        right: 30px;
    }
`;

const SkillBingoButton = styled.button`
    outline: none;
    border: none;
    width: 160px;
    height: 50px;
    border-radius: 25px;
    background-color: #203dff;
    font-size: 1.25rem;
    color: white;
    margin: 10px 0px 10px 0px;
    caret-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

const SkillBingoButtonPink = styled(SkillBingoButton)`
    background-color: #d900ff;
`;

const SkillBingoThumbnail = (props) => {
    const { sessionId, parlaySite, gameId, gameStyle, lang } = props;

    const onLaunch = (launchFree) => async () => {
        const gameWindow = openGameWindow();

        const gameLaunchData = await apiClient.post('/launchcasinogame', {
            gameId,
            parlaySite,
            free: launchFree,
            lang,
        });

        if (gameLaunchData.data.error) {
            const { error } = gameLaunchData.data;
            console.error(error);
            gameWindow.close();
            return;
        }

        const { launchUrl } = gameLaunchData.data;

        gameWindow.location = launchUrl;
    };

    return (
        <ThumbnailDiv>
            <SkillBingoImage
                src={`/assets/bingo/${gameStyle.toLowerCase()}.png`}
            />
            <ButtonsDiv>
                <SkillBingoButton onClick={onLaunch(true)}>
                    Free Play
                </SkillBingoButton>
                {sessionId && (
                    <SkillBingoButtonPink onClick={onLaunch(false)}>
                        Play
                    </SkillBingoButtonPink>
                )}
            </ButtonsDiv>
        </ThumbnailDiv>
    );
};

export default React.memo(SkillBingoThumbnail);
