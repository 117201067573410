export const millisecondsToHuman = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24);
    const humanized = [
        hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        }),
        minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        }),
        seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        }),
    ].join(':');

    return humanized;
};
