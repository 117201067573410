export const openGameWindow = () => {
    const y = window.outerHeight / 2 + window.screenY - 768 / 2 - 50;
    const x = window.outerWidth / 2 + window.screenX - 1024 / 2;

    return window.open(
        'about:blank',
        '_blank',
        `location=yes,top=${y},left=${x},height=768,width=1024,scrollbars=yes,status=yes`
    );
};
