import { useMemo, useCallback } from 'react';
import delay from 'delay';
import useCountDown from './usecountdown';

/**
 * @template {Room} T
 * @param {T} room
 */
const getTime = (room) => new Date(room.startTime);

/**
 * @template {Room} T
 * @param {Mutator} update
 * @param {T[]} rooms
 */
export const useUpdateRooms = (update, rooms = []) => {
    const minTime = useMemo(() => {
        const times = rooms.filter(getTime).map(getTime);
        return times.length ? Math.min(...times) : 0;
    }, [rooms]);

    /** @type {import('./usecountdown').OnEndFunction} */
    const onEndCountdown = useCallback(
        async (controller) => {
            try {
                await delay(5000, { signal: controller.signal });
                update();
            } catch (error) {}
        },
        [update]
    );

    useCountDown(minTime, onEndCountdown);
};

/**
 * @typedef {Object} Room
 * @property {number} nextGameStarting
 */

/**
 * @typedef {import('swr').KeyedMutator<never>} Mutator
 */
