import React from 'react';
import styled from 'styled-components';

const mobile = '(min-width: 1px) and (max-width: 576px)';

const LobbyButton = styled.button`
    outline: none;
    border: none;
    width: 330px;
    height: 80px;
    margin: 20px 30px 20px 30px;
    background: none;
    background-repeat: no-repeat;
    border-radius: 30px;
    background-image: ${(props) => `url(${props.img})`};
    background-size: cover; 
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.5);
    image-rendering: -webkit-optimize-contrast;
    transition: all .2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }

    @media ${mobile} {
      width: 281px;
      height: 68px;
    }
`;

const BingoLobbyButton = (props) => {
  const {
    setModalOpen, backgroundSrc, path,
  } = props;

  const openModal = () => {
    setModalOpen(path);
  };

  return (
    <LobbyButton img={backgroundSrc} onClick={openModal} />
  );
};

export default BingoLobbyButton;
