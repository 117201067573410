import { useCallback, useEffect } from 'react';
import iframeResize from '../utils/iframeResize';

export const useIframeResize = (maxHeight = 0) => {
    const resize = useCallback(() => iframeResize(maxHeight), [maxHeight]);

    useEffect(resize);

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [resize]);
};
