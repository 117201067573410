import Gtm from 'next-gtm';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import BingoLobbyModal from '../components/bingolobbymodal/bingolobbymodal';
import BingoThumbnail from '../components/bingothumbnail/bingothumbnail';
import Loader from '../components/loader/loader';
import LobbyButton from '../components/lobbybuttons/lobbybutton';
import SkillBingoThumbnail from '../components/skillbingothumbnail/skillbingothumbnail';
import { useIframeResize } from '../hooks/use-iframe-resize';
import { useRooms } from '../hooks/use-rooms';
import { useUpdateRooms } from '../hooks/use-update-rooms';
import CONFIG from '../utils/config';

import 'react-datepicker/dist/react-datepicker.css';
import { apiClient, apiFetcher } from '../utils/axios-client';

const mobile = '(min-width: 1px) and (max-width: 576px)';

const LobbyBody = styled.div`
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: transparent;
`;

const ScheduleDiv = styled.div`
    position: relative;
    padding: 63px 0 20px 0;
    display: grid;
    grid-template-columns: ${(props) =>
        props.itemsLength < 4
            ? `repeat(${props.itemsLength}, 0.01fr)`
            : 'repeat(4, 0.01fr)'};
    grid-column-gap: 2vw;
    grid-row-gap: 25px;
    justify-content: center;
    justify-items: center;

    @media (min-width: 576px) and (max-width: 1100px) {
        grid-template-columns: ${(props) =>
            props.itemsLength < 2
                ? `repeat(${props.itemsLength}, 0.1fr)`
                : 'repeat(2, 0.1fr)'};
        grid-column-gap: 1vw;
        grid-row-gap: 25px;
    }

    @media ${mobile} {
        grid-template-columns: ${(props) =>
            props.itemsLength < 2
                ? `repeat(${props.itemsLength}, 0.4fr)`
                : 'repeat(2, 0.4fr)'};
        grid-column-gap: 0.5vw;
        padding: 14vw 0 5vw 0;
    }
`;

const EmptyText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
    padding: 40px 0px 40px 0px;
    text-align: center;
    caret-color: transparent;

    p {
        background: #333;
        padding: 1rem;
        border-radius: 1rem;
    }
`;

const ButtonsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: solid 1.5px #414172;
    border-bottom: ${(props) =>
        props.skillBingo.length ? `solid 1.5px #414172` : null};

    flex-wrap: wrap;

    @media ${mobile} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;

const TitleText = styled.h1`
    position: absolute;
    left: 15px;
    top: 18px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #19b1f9;
    margin: 0;
`;

const TitleTextSkill = styled(TitleText)`
    top: 30px;
    left: 50px;
    @media ${mobile} {
        left: 2vw;
    }
`;

const SkillBingoDiv = styled.div`
    position: relative;
    padding: 60px 0px 0 0px;
    margin: 0 20px 0 20px;
    max-width: 1300px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const SkillBingoTextDivContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 80px;
`;

const SkillBingoTextDiv = styled.img`
    width: 339px;
    height: 143px;
    image-rendering: -webkit-optimize-contrast;
    padding-top: 30px;

    @media ${mobile} {
        padding-top: 0;
        transform: scale(0.8);
    }
`;

const BingoMarketingModal = dynamic(
    () => import(`@parlaygames/bingomarketingmodal`),
    {
        ssr: false,
    }
);

const BingoIframe = (props) => {
    const router = useRouter();
    const { jsessionId, userId, parlaySite, lang, banner } = router.query;
    const [sessionId, setSessionId] = useState(jsessionId);
    const [isOpen, setOpen] = useState(false);
    const [aliasChange, setAliasChange] = useState(false);
    const [skillBingoRooms, setSkillBingoRooms] = useState([]);

    const {
        data: scheduledBingoRooms,
        mutate: scheduledBingoRoomsMutate,
        isValidating: isScheduledBingoRoomsLoading,
    } = useSWR(`/get-bingo-rooms?lang=${lang}`, apiFetcher);

    const bingoRooms = useRooms(scheduledBingoRooms);

    const { data: sessionData, mutate: sessionMutate } = useSWR(
        '/checksession',
        apiFetcher
    );

    useUpdateRooms(scheduledBingoRoomsMutate, scheduledBingoRooms);

    useIframeResize();

    useEffect(() => {
        if (sessionData) {
            if (!sessionData.isValid) {
                setSessionId(undefined);
                return;
            }

            if (sessionData.isValid === 'no account') {
                setSessionId(undefined);
                return;
            }

            setSessionId(jsessionId);
        }
    }, [sessionData]);

    useEffect(() => {
        async function process() {
            const aliasData = await apiClient.get('/site/v2/player/alias');
            setAliasChange(aliasData.data.change);
            sessionMutate();
        }

        if (sessionId) {
            process();
        }
    }, [sessionId]);

    useEffect(() => {
        async function getSkillBingoGames() {
            try {
                const { data } = await apiClient.get('/site/v2/casino/games', {
                    params: {
                        gameType: 'SB',
                        lang,
                    },
                });

                const rooms = data.map((item) => ({
                    gameId: item.gameId,
                    style: item.style,
                }));

                setSkillBingoRooms(rooms);
            } catch (error) {}
        }

        getSkillBingoGames();
    }, [parlaySite]);

    const getAccountStatus = () =>
        apiClient
            .get('/site/v2/player/balance')
            .then((res) => res.data)
            .catch(() => ({
                cash: '0',
                bonus: '0',
                balance: '0',
            }));

    const config = {
        ...props.config,
        getAccountStatus,
        jSessionToken: sessionId,
    };

    const setModalOpen = (path) => {
        setOpen({
            open: true,
            path,
        });
    };

    const closeModal = () => {
        setOpen({
            open: false,
            path: ['/featured-event'],
        });
    };

    const toggleChangeAliasModal = () => {
        setAliasChange(false);
    };

    const renderRooms = () => {
        if (!bingoRooms.length) {
            return (
                <EmptyText>
                    {isScheduledBingoRoomsLoading ? (
                        <>
                            {' '}
                            Checking Rooms <Loader />{' '}
                        </>
                    ) : (
                        <p>There are no bingo rooms open at this time.</p>
                    )}
                </EmptyText>
            );
        }

        return (
            <ScheduleDiv itemsLength={bingoRooms.length}>
                <TitleText>CLASSIC BINGO</TitleText>
                {bingoRooms.map((bingoData) => (
                    <BingoThumbnail
                        key={bingoData.id}
                        bingoData={bingoData}
                        setModalOpen={setModalOpen}
                        sessionToken={sessionId}
                        userId={userId}
                        aliasChange={aliasChange}
                        lang={lang}
                    />
                ))}
            </ScheduleDiv>
        );
    };

    return (
        <>
            <Head>
                <title>Bingo Lobby - Parlay Games</title>
                <meta
                    name="description"
                    content="Bingo classic frame that will be attached on bingo websites."
                />
                {process.env.GOOGLETAGID ? (
                    <Gtm id={process.env.GOOGLETAGID} />
                ) : null}
            </Head>
            <LobbyBody parlaySite={parlaySite}>
                {renderRooms()}
                <BingoMarketingModal
                    {...config}
                    isOpen={isOpen.open}
                    onClose={closeModal}
                    defaultEntry={isOpen.path}
                    locales={[lang]}
                />

                {banner !== 'false' && (
                    <ButtonsDiv skillBingo={skillBingoRooms}>
                        <LobbyButton
                            backgroundSrc="/assets/icons/featuredevent.jpg"
                            path={['/featured-event']}
                            setModalOpen={setModalOpen}
                        />
                        <LobbyButton
                            backgroundSrc="/assets/icons/tournaments.jpg"
                            path={['/tournaments-and-events']}
                            setModalOpen={setModalOpen}
                        />
                        <LobbyButton
                            backgroundSrc="/assets/icons/dailyevent.jpg"
                            path={['/daily-featured-events']}
                            setModalOpen={setModalOpen}
                        />
                    </ButtonsDiv>
                )}
                <BingoLobbyModal
                    jsessionId={sessionId}
                    userId={userId}
                    toggleChangeAliasModal={toggleChangeAliasModal}
                />
                {skillBingoRooms.length ? (
                    <>
                        <SkillBingoDiv itemsLength={skillBingoRooms.length}>
                            <TitleTextSkill>SKILL BINGO</TitleTextSkill>
                            {skillBingoRooms.map((room) => (
                                <SkillBingoThumbnail
                                    key={room.gameId}
                                    sessionId={sessionId}
                                    userId={userId}
                                    parlaySite={parlaySite}
                                    gameId={room.gameId}
                                    gameStyle={room.style}
                                    lang={lang}
                                />
                            ))}
                        </SkillBingoDiv>
                        <SkillBingoTextDivContainer>
                            <SkillBingoTextDiv src="/assets/icons/skillbingobanner.png" />
                        </SkillBingoTextDivContainer>
                    </>
                ) : null}
            </LobbyBody>
        </>
    );
};

export const getServerSideProps = async ({ query }) => {
    let contentful = {};
    let siteId = '';

    try {
        const apiData = JSON.parse(CONFIG.PARLAY_SITE);
        siteId = apiData[query.parlaySite]?.siteId || '';
    } catch (error) {
        console.error(error);
    }

    try {
        const envContentful = JSON.parse(CONFIG.CONTENTFUL);
        contentful = envContentful[siteId] || {};
    } catch (error) {
        console.error(error);
    }

    return {
        props: {
            config: {
                siteId,
                contentfulSpace: contentful.CONTENTFUL_MARKETING_SPACE_ID || '',
                contentfulEnvironment:
                    contentful.CONTENTFUL_MARKETING_ENV_ID || '',
                contentfulAccessToken:
                    contentful.CONTENTFUL_MARKETING_TOKEN || '',
            },
        },
    };
};

export default BingoIframe;
